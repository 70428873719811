import React from 'react'
import {
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import BusinessIcon from '@material-ui/icons/Business'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import placeholder from 'assets/images/avatarPlaceholder.png'
import { useOrganization } from 'context/private/organization.context'
import { useAuth } from 'context/auth.context'
import { shouldFlip } from 'theme'

const useStyles = makeStyles(theme => ({
  profileWrapper: {
    flip: shouldFlip(theme),
    marginLeft: 'auto',
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    overflow: 'hidden',
    transition: '0.4s',
    '& >img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '&:hover': {
      boxShadow: '0px 0px 6px rgba(0,0,0,0.4)',
    },
  },
  button: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuItem: {
    width: '100%',
    minHeight: 35,
    borderBottom: `1px solid ${theme.palette.common.gray}`,
    padding: 0,
    '&>*': {
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      color: 'black',
      fontSize: 14,
      '& svg': {
        flip: shouldFlip(theme),
        fill: theme.palette.primary.main,
        width: 20,
        height: 20,
        marginRight: 5,
      },
    },
    '& a, a:hover': {
      textDecoration: 'none',
    },
    '&:last-of-type': {
      border: 'none',
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}))

const ProfileAvatar = ({ avatar }) => {
  const classes = useStyles()

  return (
    <div className={classes.avatar}>
      <img src={avatar || placeholder} alt="" />
    </div>
  )
}

const ProfileMenu = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { organization, logoutOrganization } = useOrganization()
  const { user, logout } = useAuth()

  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.profileWrapper}>
      <IconButton
        aria-label="profile"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
        classes={{
          root: classes.button,
        }}
      >
        <Typography
          variant="subtitle2"
          component="span"
          style={{ margin: '0 8px' }}
        >
          {user && user.first_name}
        </Typography>
        <ProfileAvatar avatar={user && user.avatar} />
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '170px',
            padding: '0 12px',
          },
        }}
      >
        {user && (
          <MenuItem onClick={handleClose} className={classes.menuItem}>
            <Link to="/user-profile" as="/user-profile">
              <EditOutlinedIcon color="primary" />
              <Typography variant="caption" component="span">
                {user.first_name}
              </Typography>
            </Link>
          </MenuItem>
        )}
        {organization && (
          <MenuItem onClick={handleClose} className={classes.menuItem}>
            <Link
              to={`/${organization.slug}/company-profile`}
              as={`/${organization.slug}/company-profile`}
            >
              <BusinessIcon color="primary" />
              <Typography variant="caption" component="span">
                {organization.title}
              </Typography>
            </Link>
          </MenuItem>
        )}
        {organization && (
          <MenuItem onClick={handleClose} className={classes.menuItem}>
            <div onClick={logoutOrganization}>
              <SwapHorizIcon color="primary" />
              <Typography variant="caption" component="span">
                <FormattedMessage id="header.links.changeOrganization" />
              </Typography>
            </div>
          </MenuItem>
        )}
        <MenuItem onClick={handleClose} className={classes.menuItem}>
          <div onClick={logout}>
            <ExitToAppOutlinedIcon color="primary" />
            <Typography variant="caption" component="span">
              <FormattedMessage id="general.logout" />
            </Typography>
          </div>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default ProfileMenu
