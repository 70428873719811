import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'
import DanaWoff from './fonts/dana/woff/dana-regular.woff'
import markPro from './fonts/ff-mark/ff-mark-pro.woff'

import { faIR, enUS } from '@material-ui/core/locale'
import { spacing } from './utils'

const persianFont = {
  fontFamily: 'dana',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      url(${DanaWoff}) format('woff')`,
}
const englishFont = {
  fontFamily: 'ff-mark-pro',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `
    url(${markPro}) format('woff')`,
}

const themeLocale = {
  fa: { faIR, font: persianFont },
  en: { enUS, font: englishFont },
}

const colors = {
  formaloo: '#F95E2F',
  lightFormaloo: 'rgba(255, 55, 6, 0.25)',
  gray: '#CECECE',
  lightGray: '#F1F3F4',
  success: '#00B424',
  gradient:
    'linear-gradient(-90deg, rgba(25,45,188,1) 41%, rgba(43,66,225,1) 93%);',
}

// A custom theme for this app
const theme = (direction, locale) =>
  createMuiTheme(
    {
      direction,
      palette: {
        type: 'light', // TODO: enable users to set type to their preference
        primary: {
          main: '#4B49FF',
          light: 'rgba(75, 73, 255, 0.2)',
        },
        secondary: {
          main: '#0D1216',
        },
        error: {
          main: '#FF1E1E',
        },
        background: {
          default: '#FFFFFF',
        },
        common: colors,
      },
      typography: {
        fontFamily: themeLocale[locale].font.fontFamily,
        fontSize: 14,
        // Tell Material-UI what's the font-size on the html element is.
        // htmlFontSize: 16,
      },
      // overrides
      props: {
        MuiButton: {
          disableElevation: true,
          disableFocusRipple: true,
          disableTouchRipple: true,
        },
        MuiAppBar: {
          color: 'default',
        },
        MuiTextField: {
          variant: 'outlined',
        },
        MuiFormHelperText: {
          component: 'div',
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': themeLocale[locale].font,
          },
        },
        MuiAppBar: {
          colorDefault: {
            backgroundColor: 'white',
          },
        },
        MuiPaper: {
          elevation4: {
            boxShadow: '0px 4px 6px  rgba(0,0,0,0.16)',
          },
          elevation1: {
            boxShadow: 'none',
          },
        },
        MuiButton: {
          root: {
            textTransform: 'capitalize',
            minHeight: '35px',
            fontSize: 13,
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          outlinedPrimary: {
            color: 'black',
            borderColor: 'black',
          },
        },
        MuiFormHelperText: {
          contained: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
        MuiTableCell: {
          root: {
            border: '1px solid #e0e0e0',
            padding: spacing(2, 1),
          },
        },
        MuiDialogTitle: {
          root: {
            padding: spacing(0, 1),
            height: 45,
            background: colors.gray,
          },
        },
        MuiListItem: {
          button: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        MuiIconButton: {
          root: {
            '&:hover': {
              backgroundColor: 'none',
            },
          },
        },
      },
    },
    themeLocale[locale],
  )

export default theme
