import { config, getCurrentBusiness } from './utils'
import { httpClient } from 'utils/http'

import API from 'settings/actions.settings'
import { createFormData } from 'utils/form'

const active_business = getCurrentBusiness()

export const organizations = () => ({
  get: slug =>
    httpClient.get(
      `${API.baseUrl}/v2/businesses/${active_business}/`,
      config({ apiKey: API.apiKey }),
    ),

  getAll: () =>
    httpClient.get(`${API.baseUrl}/v1/customers/businesses/`, config()),

  create: formValues =>
    httpClient.post(
      `${API.baseUrl}/v2/businesses/`,
      createFormData(formValues),
      config({ apiKey: API.apiKey }),
    ),
  update: formValues =>
    httpClient.patch(
      `${API.baseUrl}/v2/businesses/${active_business}/`,
      createFormData(formValues),
      config({ apiKey: API.apiKey, params: { active_business } }),
    ),
})
