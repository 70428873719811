import axios from 'axios'
import { getOrRefreshToken } from 'api-client/auth'
import { AppStorage } from 'utils/persistence'
import { getCurrentBusiness } from 'api-client/utils'
import OAuthServer from 'settings/oauth.settings'

const clearStoredData = () => {
  AppStorage.clear()
  window.location.reload()
}

export default () => {
  axios.interceptors.request.use(config => {
    const params = config.params || {}
    const active_business = params.active_business || getCurrentBusiness()

    return { ...config, params: { ...params, active_business } }
    // return config
  })

  axios.interceptors.response.use(
    response => {
      // Return a successful response back to the calling service
      if (response.data.data) return response.data.data

      return response.data
    },
    error => {
      // Return any error which is not due to authentication back to the calling service
      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error.response.data.errors)
        })
      }

      // Logout user if token refresh didn't work or user is disabled
      if (error.response.message === 'invalid_grant') {
        clearStoredData()

        return new Promise((resolve, reject) => {
          reject(error.response.data.errors)
        })
      }

      // 👇 error 401

      // if 401 happened when refreshing the token,
      // the session has expired and should re-auth by going to icas
      if (error.config.url === OAuthServer.authorizationUrl) {
        clearStoredData()
      }

      // because of the interceptors getOrRefreshToken rejection will never get handled in it's own catch
      // Try request again with new token
      return getOrRefreshToken()
        .then(token => {
          // New request with new token
          const { config } = error
          config.headers.Authorization = `JWT ${token}`

          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then(response => {
                resolve(response)
              })
              .catch(errors => {
                reject(errors)
              })
          })
        })
        .catch(e => {
          return new Promise((resolve, reject) => {
            reject(e)
          })
        })
    },
  )
}
