import React from 'react'
import { OrganizationProvider } from './organization.context'
import { UserProvider } from './user.context'

function PrivateProviders({ children }) {
  return (
    <UserProvider>
      <OrganizationProvider>{children}</OrganizationProvider>
    </UserProvider>
  )
}

export { PrivateProviders }
