import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { organizations } from 'api-client/organizations'
import { showErrors } from 'utils/logger/showErrors'
import { useNotification } from 'context/notification.context'
import {
  getCurrentOrganization,
  setCurrentBusiness,
  setCurrentOrganization,
  removeCurrentBusiness,
  removeCurrentOrganization,
} from 'api-client/utils'

const OrganizationContext = React.createContext()

const OrganizationProvider = props => {
  const { push } = useHistory()
  const { notify } = useNotification()
  const [state, setState] = useState(null)

  const saveOrganizationSlugsOnStorage = org => {
    setCurrentOrganization(org.slug)
    setCurrentBusiness(org.business_identifier)
  }

  const setOrganization = ({ customer: organization }) => {
    saveOrganizationSlugsOnStorage(organization)
    setState(organization)
  }

  const checkLocalStorage = () => {
    const existingOrganization = getCurrentOrganization()
    if (!existingOrganization) {
      removeCurrentBusiness()
      return false
    }
    return existingOrganization
  }

  const getOrganization = async organizationSlug => {
    try {
      const response = await organizations().get(organizationSlug)
      setOrganization(response)
    } catch (error) {
      showErrors(error, notify)
    }
  }

  useEffect(() => {
    const checkOrganization = checkLocalStorage()
    if (checkOrganization) {
      getOrganization(checkOrganization)
    } else {
      push('/organizations')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLocalStorage()])

  const logoutOrganization = () => {
    removeCurrentOrganization()
    removeCurrentBusiness()
    setState(null)
    push('/organizations')
  }
  return (
    <OrganizationContext.Provider
      value={{ organization: state, setOrganization, logoutOrganization }}
      {...props}
    />
  )
}

const useOrganization = () => {
  const context = React.useContext(OrganizationContext)
  if (context === undefined) {
    throw new Error(
      `useOrganization must be used within a OrganizationProvider`,
    )
  }
  return context
}

export { OrganizationContext, OrganizationProvider, useOrganization }
