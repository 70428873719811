import setupAxios from 'api-client/config/axios.config'

import { inDevMode } from 'settings'

export default [
  setupAxios,
  // init services eg. sentry
  () => {
    if (!inDevMode) {
      import('utils/logger').then(({ Logger }) => Logger.setup())
    }
  },
  // log in user
  // set current org if found
  // get all businesses
  // get profile
  // get user accesses,
].map(fn => ({ run: fn }))
