import * as Sentry from '@sentry/react'
import settings from 'settings/sentry.settings'
import setupLogger from './setup'

class Logger {
  static setup() {
    setupLogger()
  }

  static middleWare(fn, ...args) {
    if (settings.DSN) {
      fn(...args)
    }
  }

  /**
   *
   * @param {object} user
   */
  static setUser(user) {
    Logger.middleWare(Sentry.setUser, user)
  }

  static clearUser() {
    Logger.middleWare(Sentry.configureScope, scope => scope.setUser(null))
  }
}

export default Logger
