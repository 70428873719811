/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { SnackbarProvider, useSnackbar } from 'notistack'
import ClearIcon from '@material-ui/icons/Clear'

const NotificationContext = React.createContext({})

function NotificationProvider({ children, ...props }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const clearNotifications = () => closeSnackbar()

  const addNotification = (message, options) =>
    enqueueSnackbar(message, options)

  const contextValue = {
    clearNotification: useCallback(() => clearNotifications(), []),
    notify: {
      success: useCallback(
        (message, options = {}) =>
          addNotification(message, { variant: 'success', ...options }),
        [],
      ),
      warning: useCallback(
        (message, options = {}) =>
          addNotification(message, { variant: 'warning', ...options }),
        [],
      ),
      error: useCallback(
        (message, options = {}) =>
          addNotification(message, { variant: 'error', ...options }),
        [],
      ),
      info: useCallback(
        (message, options = {}) =>
          addNotification(message, { variant: 'info', ...options }),
        [],
      ),
    },
  }

  return (
    <NotificationContext.Provider value={contextValue} {...props}>
      {children}
    </NotificationContext.Provider>
  )
}

function useNotification() {
  const context = React.useContext(NotificationContext)
  if (context === undefined) {
    throw new Error(
      `useNotification must be used within a NotificationProvider`,
    )
  }
  return context
}

function withNotification(Component) {
  return function WrappedComponent(props) {
    const notificationContext = useNotification()
    return <Component {...{ ...props, ...notificationContext }} />
  }
}

function GlobalNotificationProvider({ children }) {
  const notistackRef = React.createRef()
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key)
  }
  const closeIconStyle = {
    color: 'white',
    fontSize: 14,
    cursor: 'pointer',
    margin: '0 4px',
  }
  return (
    <SnackbarProvider
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      action={key => (
        <ClearIcon onClick={onClickDismiss(key)} style={closeIconStyle} />
      )}
      maxSnack={3}
      autoHideDuration={3000}
    >
      <NotificationProvider>{children}</NotificationProvider>
    </SnackbarProvider>
  )
}

export {
  NotificationContext,
  GlobalNotificationProvider,
  NotificationProvider,
  withNotification,
  useNotification,
}
