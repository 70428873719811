import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
  },
}))

export default function AppContainer({ className, ...props }) {
  const classes = useStyles()

  return (
    <Container
      maxWidth="xl"
      {...props}
      className={clsx(classes.root, className)}
    />
  )
}
