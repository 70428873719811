export default {
  GREETING: 'سلام {name}',
  // resources
  'resources.organization': 'سازمان',
  'resources.organization.title': 'نام سازمان/شرکت',
  'resources.organization.select.label': ' سازمان/شرکت خود را انتخاب کنبد',
  'resources.organization.create.button': ' سازمان/شرکت جدید بسازید',
  //header
  'header.links.dashboard': 'میزکار',
  'header.links.customers': 'مشتریان',
  'header.links.campaigns': 'ارتباط با مشتری',
  'header.links.changeOrganization': 'تغییر سازمان/شرکت',
  //
  'resources.customers': 'مشتریان',

  'customers.page.title': 'مشتریان شما',
  'customers.table.count': 'مشتریان شما: {count}',
  // actions
  'actions.search': 'جستجو',
  'actions.create': 'ایجاد',
  //
  'actions.create.resource': 'ایجاد {resource}',
  // forms
  'forms.fields.common.logo': 'لوگو',
  'forms.fields.currentPass': 'رمزعبور فعلی*',
  'forms.fields.newPass': 'رمزعبور جدید*',
  'forms.fields.repeatPass': 'تکرار رمزعبور*',
  'forms.fields.common.website': 'آدرس وبسایت',
  'forms.fields.common.description': 'توضیحات',
  'forms.fields.file': 'فایل',
  // validation
  'forms.validation.required': 'پر کردن این فیلد الزامی است',
  'forms.validation.repeatPass': 'تکرار رمزعبور با رمزعبور جدید مطابقت ندارد',
  //user
  'user.profile.changePassword': 'تغییر رمزعبور',
  'organizations.create': 'سازمان جدید',
  // customer
  customer: 'مشتری',
  'customer.profile.level': 'توان',
  'customer.profile.score': 'امتیاز',
  'customer.profile.details.basicInfo.header': 'جزئیات',
  'customer.profile.details.additionalInfo.header': 'اطلاعات تکمیلی',
  'customer.profile.details.basicInfo.empty': 'هنوز اطلاعاتی ثبت نشده‌است',
  'customer.profile.details.basicInfo.edit.header': 'ویرایش جزئیات',
  'customer.profile.details.additionalInfo.edit.header':
    'ویرایش اطلاعات تکمیلی',
  'customer.profile.details.tag.header': 'تگ',
  'customer.profile.details.projects.header': 'آخرین پروژه‌ها/سفارشات',
  'customer.profile.details.notes.header': 'یادداشت‌ها',
  'customer.profile.details.notes.add': 'یادداشت‌ جدید',
  'customer.profile.details.notes.add.placeholder': 'یادداشت‌ شما ...',
  'customer.profile.details.notes.writer': 'نویسنده: ',
  'customer.profile.details.lastActivities.header': 'آخرین فعالیت‌ها',
  'customer.profile.details.lastActivities.order':
    'سفارش با عنوان {type} ثبت شده است',
  'customer.profile.details.lastActivities.campaign':
    'کمپین با عنوان {type} ایجاد شده است',
  'customer.profile.details.lastActivities.payment':
    'پرداخت با عنوان {type} ثبت شده است',
  'customer.profile.details.lastActivities.support':
    'تیکت با عنوان {type} ثبت/ویرایش شده است',
  'customer.profile.details.lastActivities.support_reply':
    'تیکت با عنوان {type} پاسخ داده شده است',
  'customer.profile.details.Activity.header': 'فعالیت‌',
  'customer.profile.details.Activity.viewAll': 'مشاهده همه ←',
  'customer.profile.details.fields.viewAll': 'مشاهده همه فیلدهای فرم',
  'customer.profile.details.fields.add': 'افزودن فیلد {icon}',
  'customer.profile.allFields.title': 'عنوان فیلد',
  'customer.profile.allFields.value': 'محتوا فیلد',
  'customer.profile.allFields.displayingInProfile':
    'نمایش فیلد در پروفایل مشتری',
  'customer.profile.allFields.userLink': 'فیلد‌های {customer}',
  'customer.delete.successfully': 'مشتری با موفقیت حذف شد',
  'customer.delete.areYouSure': 'آیا از حذف این مشتری اطمینان دارید؟',
  'customer.activities.header': ' لیست فعالیت‌های {customer}',
  'customer.orders.header': ' لیست پروژه‌ها‌/سفارش‌های {customer}',
  'customer.activities.empty': ' فعالیتی برای این مشتری ثبت نشده است',
  'customer.orders.empty': ' پروژه‌/سفارشی برای این مشتری ثبت نشده است',
  'customer.activities.table.message': ' فعالیت',
  'customer.activities.table.type': ' نوع',
  'customer.activities.table.type.order': 'ثبت سفارش',
  'customer.activities.table.type.campaign': 'ایجاد کمپین',
  'customer.activities.table.type.payment': 'پرداخت',
  'customer.activities.table.type.support': 'ایجاد/ویرایش تیکت',
  'customer.activities.table.type.support_reply': 'پاسخ به تیکت',
  'customer.activities.table.date': ' تاریخ',
  'customer.activities.table.type.submit': 'تکمیل فرم',

  'customer.notes.header': 'لیست یادداشت‌های {customer}',
  'customer.notes.noteText': 'یادداشت',
  'customer.notes.date': 'تاریخ',
  'customer.notes.emptyList': 'هنوز یادداشتی اضافه نکرده‌اید',

  'customer.field.title': 'عنوان فیلد',
  'customer.field.type': 'نوع فیلد',
  'customer.field.choiceItem.label': 'گزینه {count}',
  'customer.gamification.data.empty':
    'اطلاعات gamification این مشتری در دسترس نمی‌باشد',
  'customer.gamification.F': 'مشتری هر چند وقت یک بار خرید می کند',
  'customer.gamification.R': 'آخرین زمانی که مشتری خرید کرده است',
  'customer.gamification.S': ' ',
  'customer.gamification.AS': 'میانگین توان مشتری در تمامی ماه‌های قبل',
  'customer.gamification.NO': 'تعداد سفارشات مشتری',
  'customer.gamification.TM': 'مبلغ کل خرید',
  'customer.gamification.NS': 'امتیاز جدید',
  'customer.gamification.PF': ' ',
  'customer.gamification.PR': ' ',
  'customer.gamification.AQS': 'میانگین توان مشتری در ۳ ماه گذشته',
  'customer.gamification.NoP': 'تعداد پرداخت‌های مشتری',
  'customer.gamification.cashBack': 'اعتبار هدیه',
  'customer.gamification.cashBack.currency': '{value} تومان',

  'tags.clearAll': 'حذف فیلتر تگ‌ها',
  'tags.edit': 'ویرایش تگ',
  'tags.create': 'ایجاد تگ جدید',
  'tags.title': 'عنوان',
  'tags.create.success.message': 'تگ جدید با موفقیت ایجاد شد',
  'tags.withCustomer': 'تگ‌هایی که مشتری دارند',

  'field.email.invalid': 'ایمیل نامعتیر است',
  'field.general.invalid': '{field} معتبر وارد نمایید',
  'field.number.label': 'عدد',
  'field.phone.label': 'شماره تلفن',
  'field.money.label': 'پول',
  'field.email.label': 'ایمیل',
  'field.website.label': 'وب سایت',
  'field.shortText.label': 'متن کوتاه',
  'field.longText.label': 'متن بلند',
  'field.multipleSelect.label': 'چند‌انتخابی',
  'field.choice.label': 'تک‌انتخابی',
  'field.dropdown.label': 'منو کشویی',
  'field.email.fullName': 'نام و نام خانوادگی',
  'field.city.label': 'شهر',
  'field.choiceItems.label': 'گزینه‌ها*',
  'field.language.label': 'زبان',
  'field.shortText.invalid': 'متن وارد شده بیشتر از ۲۵۵ کاراکتر است',
  'field.upload.status.empty':
    'برای آپلود، فایل را در خط‌چین رها کنید و یا روی آن کلیک کنید',
  'field.upload.status.dragged': 'فایل را در خط‌چین رها کنید',

  'company.field.logo': 'لوگو',
  'company.field.title': 'نام کمپانی',
  'company.field.description': 'توضیحات',
  'company.field.phone': 'شماره موبایل',
  'company.field.website': 'وب‌سابت',
  'company.field.rfmAnalysis': 'RFM Analysis',

  'campaigns.page.title': 'فهرست کمپین‌های شما',
  'campaigns.table.count': 'تعداد کمپین‌ها: {count}',

  'campaign.type.sms.label': 'ارتباط از طریق پیام کوتاه',
  'campaign.type.sms.createTitle': 'ایجاد یک پیام کوتاه',
  'campaign.type.sms.description': 'ویژه نسخه سازمانی',
  'campaign.type.email.label': 'ارتباط از طریق ایمیل',
  'campaign.type.email.createTitle': 'ایجاد ایمیل',
  'campaign.type.email.description': 'ویژه نسخه سازمانی',
  'campaign.title': 'عنوان کمپین',
  'campaign.description': 'توضیحات',
  'campaign.tags': 'تگ',
  'campaign.tags.header': 'برای چه کسانی میخواهید بفرستید؟',
  'campaign.allCustomers': 'همه مشتریان',
  'campaign.email.subject': 'موضوع ایمیل*',
  'campaign.email.text': 'متن ایمیل',
  'campaign.sms.text': 'متن پیامک',
  'campaign.message.content': 'اطلاعات تکمیلی',
  'campaign.reportPage.button': 'مشاهده گزارشات',
  'campaign.confirmation.message': ' این امکان مخصوص نسخه سازمانی است',
  'campaign.send.success': 'کمپین شما با موفقیت ارسال شد',
  'campaign.customers.count': '{count} مشتری در این بخش وجود دارد',
  'campaign.scheduledDate': 'ارسال شده در {date}',
  'campaign.emailSubject': 'موضوع ایمیل: {subject}',
  'campaign.message': 'متن پیام: {message}',
  'campaign.visitCount': 'تعداد بازدید',
  'campaign.sentTags.header': 'ارسال به',
  'campaign.sendCampaign': 'ارسال کمپین',
  'campaign.editCampaign': 'ویرایش کمپین',
  'campaign.stats.customerCount': 'ارسال شده',
  'campaign.stats.visitCount': 'باز شده',
  'campaign.stats.receivedCount': 'دریافت شده',
  'campaign.stats.noStats': 'در حال حاضر آماری در دسترس نمی‌باشد',

  'campaign.status.sent': 'ارسال شده',
  'campaign.status.draft': 'پیش‌نویس',
  'campaign.status.canceled': 'لغو شده',
  'campaign.status.scheduled': 'زمان‌بندی شده',

  'languages.select.label': 'تغییر زبان',
  'languages.select.confirmation': 'آیا از تغییر زبان اطمینان دارید؟',

  'dashboard.stats.sentCampaign.total': 'مجموع کمپین‌های ارسالی: {stat}',
  'dashboard.stats.orders': 'آمار سفارشات',
  'dashboard.stats.customer.total': 'مجموع مشتری ها: {stat}',
  'dashboard.stats.customer': 'آمار تعداد مشتریان',
  'dashboard.stats.activeForm': 'آمار پاسخ های ارسال شده',
  'dashboard.stats.orders.Total': 'مجموع سفارشات: {stat}',
  'dashboard.stats.activeForm.total': 'مجموع پاسخ های ارسال شده: {stat}',
  'dashboard.stats.monthly': 'ماهانه',
  'dashboard.stats.daily': 'روزانه',
  'dashboard.stats.total.revenue': 'درآمد ناخالص',
  'dashboard.stats.total.orders': 'سفارشات',
  'dashboard.stats.total.customers': 'کل مشتریان',
  'dashboard.stats.total.newCustomers': 'مشتریان جدید',
  'dashboard.stats.average.orders': 'میانگین مبلغ سفارش',
  'dashboard.stats.average.productsInOrders': 'میانگین کالا در سفارش',
  'dashboard.stats.refundRate': 'نرخ بازگشت مبلغ',
  'dashboard.stats.average.reordering': 'میانگین زمان تکرار سفارش',
  'dashboard.stats.duplicatedCustomers': 'مشتریان تکراری',
  'dashboard.stats.ComingSoon': 'نیازمند اطلاعات بیشتر ',
  'dashboard.stats.box.value': '{count}',
  'dashboard.welcoming': 'به فرمالو CDP خوش‌آمدید!',

  'general.noResult.message': 'هنوز {key}ی اضافه نکرده‌اید',
  'general.add': 'اضافه کردن',
  'general.save': 'ذخیره',
  'general.saveAndContinue': 'ذخیره و ادامه',
  'general.saved': 'تغییرات با موفقیت ذخیره شد',
  'general.back': 'برگشت',
  'general.cancel': 'انصراف',
  'general.send': 'ارسال',
  'general.saveDraft': 'ذخیره پیشنویس',
  'general.dropdown.empty': 'موردی یافت نشد',
  'general.logout': 'خروج',
  'general.yes': 'بله',
  'general.no': 'خیر',
  'general.N/A': 'ناموجود',
  //page title
  'customer.profile': 'پروفایل مشتری',
  'customer.list': 'لیست مشتریان',
  'customer.fields': 'فیلدها',
  'customer.activities': 'فعالیت‌ها',
  'customer.notes': 'یادداشت‌ها',
  'tags.list': 'لیست تگ‌ها',
  'tags.mapView': 'نقشه',
  'tags.map.filter.deselectAll': 'پاک کردن همه',
  'tags.map.filter.selectAll': 'انتخاب همه',
  'tags.map.notEnoughData':
    'متاسفانه تعداد مشتریان/مخاطبان و یا تگ‌های شما به اندازه کافی نیست. این صفحه زمانی که تعداد کافی مشتری/مخاطب و تگ داشته باشید به‌طور خودکار برایتان فعال می‌شود.',
  'campaign.list': 'لیست کمپین‌ها',
  'campaign.create': 'ایجاد کمپین',
  'campaign.edit': 'جزئیات کمپین',
  'campaign.report': 'گزارش کمپین',
  'user.profile': 'پروفایل کاربری شما',
  'organization.profile': 'پروفایل سازمان/کمپانی',
  'organization.create': 'ایجاد سازمان/کمپانی',
  'organization.edit': 'ویرایش سازمان/کمپانی',
  'organization.show': 'نمایش سازمان/کمپانی',
  'organization.list': '‌لیست سازمان/کمپانی‌ها',
  'integrations.description': 'توضیحات کانکشن',
  'integrations.list.empty': 'کانکشنی یافت نشد',
  'integration.create': 'ایجاد کانکشن',
  'source.setup': 'Source setup',
  'integrations.add': 'اضافه کردن منبع داده',
  'integrations.tabs.overview': 'Overview',
  'integrations.tabs.settings': 'Settings',
  'integrations.tabs.customActions': 'Custom Actions',
  'integration.details.header': 'Sources / {title}',
  'integrations.header':
    'برای یکپارچه سازی داده های خود با فرمالو، روی دکمه افزدون منبع داده جدید کلیک فرمایید.',
  'integration.name': 'name',
  'integration.website': 'website',
  'integration.website.placeholder': 'https://',
  'integration.description': 'Connection details',
  'integration.installation': 'Installation',
  'integration.enable': 'Enable',
  'integration.enable.description':
    'Enabling this data source will allow it to send data into Formaloo and any connected enabled destinations.',
  'integration.key': 'API Key',
  'integration.secret': 'Secret Key',
  'integration.WriteOnly': 'Write Only Key',
  'integration.guide': 'Use this guide to configure this source',
  'source.create.success': 'source جدید با موفقیت ایجاد شد',
  'source.deleted.success': 'Source deleted successfully',
  'source.delete.areYouSure': 'از حذف این source اطمینان دارید ؟',
  'source.updated.success': 'Source updated successfully',
  'source.delete': 'Delete source',
  'source.save': 'Save changes',
  'actions.list.empty': 'No custom actions yet!',
  'actions.list.header': 'Actions',
  'actions.add': 'add action',
  'action.operation.create': 'create',
  'action.operation.read': 'read',
  'action.operation.update': 'update',
  'action.operation.delete': 'delete',
  'action.name': 'Title',
  'action.tags': 'Tags',
  'action.score': 'Score',
  'action.description': 'Description',
  'action.operation.type': 'Operation type',
  'action.create': 'create',
  'action.group.type': 'Group type',
  'action.score.description': '',
  'action.create.successfully': 'New Action created successfully',
  'action.update.successfully': 'Action updated successfully',
  'actions.table.back': 'Back to actions',
  'action.create.header': 'Create new action',
  'action.edit.header': 'Edit action',
  'action.slug': 'Slug',

  // CDP Assistance Bot
  'cdpBot.welcome': 'سلام {user}!',
  'cdpBot.welcome.onboarding': 'برای شروع مراحل زیر را طی کنید',
  'cdpBot.username': 'کاربر عزیز',
  'cdpBot.welcome.message':
    'با اتصال مشتریان خود به Formaloo CDP به رشد بیزینس خود کمک کنید',
  // Cdp-bot actions
  // add customer
  'cdpBot.actions.add.customer': 'اضافه کردن مشتریان جدید',
  'cdpBot.actions.add.customer.types':
    'با چه روشی مشتریان جدید خود را اضافه می‌کنید؟',
  'cdpBot.actions.add.customer.byFile': 'آپلود فایل',
  'cdpBot.actions.add.customer.byOption': 'ساخت مشتری',
  'cdpBot.actions.import.customer.successfully':
    'مشتریان جدید با موفقیت اضافه شدند. {link} را چک کنید',
  'cdpBot.actions.import.customer.unsuccessfully':
    'اشکالی در انتقال مشتریان رخ داده است',
  'cdpBot.actions.import.customer.unComplete':
    'اشکالی در انتقال برخی از مشتریان رخ داده است',
  'cdpBot.actions.import.customer.sample': 'دانلود نمونه اکسل',
  'cdpBot.actions.add.customer.successfully':
    'مشتری جدید با موفقیت اضافه شد. {link} را چک کنید',
  'cdpBot.actions.add.customer.uploadFile':
    'از طریق باکس زیر فایل خود را آپلود نمایید',
  'cdpBot.actions.add.customer.fullName':
    'نام و نام‌خانوادگی مشتری را وارد نمایید',
  'cdpBot.actions.customers.link': 'لیست مشتریان',
  'cdpBot.actions.add.customer.email': 'ایمیل را وارد نمایید',
  'cdpBot.actions.add.customer.phone': 'شماره تلفن را وارد نمایید',
  'cdpBot.actions.add.customer.score':
    'اگر امتیاز اولیه‌ای میخواهید به مشتری دهید وارد نمایید',
  'cdpBot.actions.add.customer.tags':
    'اگر تگی میخواهید به مشتری اضافه کنید، از لیست انتخاب نمایید',
  'cdpBot.actions.add.customer.fileFormats':
    'فقط فایل های از نوع xls, xlsx و csv قابل قبول می‌باشند',

  // onboarding
  'cdpBot.onboarding.step.creatForm.title':
    'اولین فرم، نظرسنجی یا آزمون خود را بسازید',
  'cdpBot.onboarding.step.creatForm.description':
    'به راحتی در چند ثانیه هرگونه فرم و آزمون دلخواهی را کاملا رایگان بسازید، در وبسایت خود قرار دهید و نتایج را آنالیز کنید، به همراه فضای ابری اختصاصی، سیستم احراز هویت، امتیازدهی، تصحیح اتوماتیک، اتصال به درگاه پرداخت، ماژول‌های اتوماسیون و بدون نیاز به هیچگونه کد نویسی!',
  'cdpBot.onboarding.step.creatForm.button': 'ایجاد فرم',
  'cdpBot.onboarding.step.invite.title': 'دعوت همکاران و هم‌تیمی',
  'cdpBot.onboarding.step.invite.description':
    'شما می توانید همکاران خود را به سامانه خود اضافه کنید و به هر کدام از آنها سطح دسترسی مخصوص خود را بدهید.',
  'cdpBot.onboarding.step.invite.button': 'دعوت از تیم',
  'cdpBot.onboarding.step.customer.title': 'وارد کردن مشتریان/مخاطبان',
  'cdpBot.onboarding.step.customer.description':
    'تمام مشتریان و مخاطبان خود را به صورت تکی و یا گروهی وارد نمایید و رفتار آنها را در کسب و کار خود آنالیز نمایید.',
  'cdpBot.onboarding.step.customer.button.import': 'وارد کردن مشتریان',
  'cdpBot.onboarding.step.customer.button.example': 'دانلود نمومه اکسل',
  'cdpBot.onboarding.step.customer.button.template': 'دانلود قالب اکسل',
  'cdpBot.onboarding.step.customer.file': 'فایل: {file}',
  'cdpBot.onboarding.step.customer.file.name': 'لیست مشتریان',
  'cdpBot.onboarding.step.customer.import.status': 'وضعیت: {status}',
  'cdpBot.onboarding.step.customer.import.status.new': 'در حال آپلود',
  'cdpBot.onboarding.step.customer.import.status.queued':
    'در حال پردازش، لطفا چند دقیقه دیگر چک بفرمایید',
  'cdpBot.onboarding.step.customer.import.status.inProgress': 'در حال بارگذاری',
  'cdpBot.onboarding.step.customer.import.status.imported':
    'وارد سازی با موفقیت انجام شد',
  'cdpBot.onboarding.step.customer.import.status.failed':
    'خطا در هنگام وارد سازی اطلاعات',
  'cdpBot.onboarding.step.customer.import.status.canceled':
    'وارد سازی اطلاعات کنسل شد',
  'cdpBot.onboarding.step.customer.reTry': 'دوباره تلاش کن',
  'cdpBot.onboarding.step.completed': 'همه‌ مراحل را با موفقیت گذراندید',
  'cdpBot.onboarding.step.mainPageLink': 'برگشت به صفحه اصلی ',
  'cdpBot.onboarding.step.skip': 'بعدا',
  'cdpBot.onboarding.step.skip.all': 'رد کردن تمام مراحل',
  'cdpBot.onboarding.step.learnMore': 'بیشتر',
  'cdpBot.onboarding.step.show.errors': 'نمایش خطا‌ها',

  //customer demo
  'cdpBot.customer.demo': 'مشاهده دیتای دمو (وارد سازی داده آزمایشی)',
  'cdpBot.customer.demo.description': 'وارد سازی داده آزمایشی',

  title: 'عنوان',
  status: 'وضعیت',
  created_at: 'زمان ساخت',
  updated_at: 'به‌روزرسانی',
  scheduled_at: 'زمان اجرا',
  email: 'ایمیل',
  sms: 'پیامک',
  actions: 'عملیات',
  tags: 'تگ‌ها',
  color: 'رنگ',
  integrations: 'کانکشن‌ها',
  copy: 'کپی',
  copied: 'کپی شد',
  here: 'اینجا',
  skip: 'بعدا',
  'are you sure?': 'آیا اطمینان دارید؟',

  // table
  'table.empty': 'متاسفانه هنوز اطلاعاتی برای نمایش وجود ندارد...',
  'table.labelDisplayedRows': '{from}-{to} از {count}',
  'table.moreThan': 'بیش از {to}',
  'table.recordsCount': '{count} رکورد',

  'filters.condition.label': 'شرط',
  'filters.operator.label': 'عملگر',
  'filters.operand.label': 'مقدار',
  'filters.chip.label': '{field} {condition}: {value}',

  'sortBy.label': 'مرتب‌سازی بر اساس',
  'sortBy.asc': 'صعودی',
  'sortBy.desc': 'نزولی',
  'sortBy.chip.label': 'مرتب‌سازی بر اساس: {field} - {direction}',

  'filters.conditions.exact': 'برابر است با',
  'filters.conditions.iexact': '(غیرحساس به حروف کوچک و بزرگ) برابر است با',
  'filters.conditions.contains': 'شامل',
  'filters.conditions.icontains': '(غیرحساس به حروف کوچک و بزرگ) شامل',
  'filters.conditions.lt': 'کمتر از',
  'filters.conditions.lte': 'کمتر از یا برابر با',
  'filters.conditions.gt': 'بیشتر از',
  'filters.conditions.gte': 'بیشتر از یا برابر با',
  filter: 'فیلتر',

  // pagination
  'pagination.rows per page': 'تعداد سطرهای هر صفحه',
  'pagination.first page': 'صفحه اول',
  'pagination.last page': 'صفحه آخر',
  'pagination.previous page': 'صفحه قبل',
  'pagination.next page': 'صفحه بعد',

  'tags.tableTitle': 'تگ‌ها',

  upgrade: 'ارتقا حساب',
}
