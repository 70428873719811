import React, { useState } from 'react'
import { auth, profile } from 'api-client'
import { LoadingPage } from 'components/common/loading'
import { AppStorage } from 'utils/persistence'
import { useMountEffect } from 'utils/react-hooks'
import { useNotification } from './notification.context'
import { showErrors } from 'utils/logger/showErrors'

const AuthContext = React.createContext()

function AuthProvider(props) {
  // code for pre-loading the user's information if we have their token in
  // localStorage goes here
  const [state, setState] = useState({})
  const { notify } = useNotification()

  const login = async (postLoginRedirectUrl = '/') => {
    const {
      // data: {
      token,
      // },
      errors,
    } = await auth().getAccessToken()

    if (token) {
      AppStorage.setToken(token)
      setState({
        user: {},
        error: undefined,
        pending: false,
        isAuthenticated: true,
      })

      // history.replace(postLoginRedirectUrl)
      return Promise.resolve(token)
    } else
      setState({
        pending: false,
        error: errors,
        user: {},
        isAuthenticated: false,
      })

    return Promise.reject(errors)
  }

  const getProfile = async () => {
    setState({ ...state, pending: true, error: false })
    const { operator, errors } = await profile().get()

    if (operator)
      setState({
        error: undefined,
        pending: false,
        user: operator,
        isAuthenticated: true,
      })
    else
      setState({
        pending: false,
        error: errors,
        user: {},
        isAuthenticated: false,
      })
  }

  const loginAndGetProfile = async () => {
    if (AppStorage.getSession()) {
      login().then(getProfile).catch(err => showErrors(err, notify))
    }
  }

  useMountEffect(() => {
    loginAndGetProfile()
  })

  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.

  const logout = () => {
    setState({})
    AppStorage.clear()
    window.location.href = auth().getLogoutUrl()
  }

  // clear the token in localStorage and the user data
  // note, I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.

  if (state.pending) {
    return <LoadingPage />
  }

  if (state.error) {
    throw Error(state.error)
  }

  return (
    <AuthContext.Provider
      value={{ ...state, login: loginAndGetProfile, logout }}
      {...props}
    />
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthContext, AuthProvider, useAuth }
