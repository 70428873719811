import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { AuthProvider } from './auth.context'
import { IntlProvider } from './intl.context'
import { ThemeProvider } from './theme.context'
import { GlobalNotificationProvider } from './notification.context'
import { ReactQueryConfigProvider } from 'react-query'

import {
  QueryParamProvider,
  transformSearchStringJsonSafe,
} from 'use-query-params'

const queryStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
}

const queryConfig = { queries: { refetchOnWindowFocus: false } }

function AppProviders({ children }) {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <IntlProvider>
        <ThemeProvider>
          <GlobalNotificationProvider>
            <Router>
              <QueryParamProvider
                ReactRouterRoute={Route}
                stringifyOptions={queryStringifyOptions}
              >
                <AuthProvider>{children}</AuthProvider>
              </QueryParamProvider>
            </Router>
          </GlobalNotificationProvider>
        </ThemeProvider>
      </IntlProvider>
    </ReactQueryConfigProvider>
  )
}

export { AppProviders }
export { PrivateProviders } from './private'
