import React, { useEffect } from 'react'
import { LoadingPage } from 'components/common/loading'
import { AppStorage } from 'utils/persistence'
import StorageSettings from 'settings/storage.settings'
import { useAuth } from 'context/auth.context'
import { AppContainer } from 'components/layout'

const AuthenticatedApp = React.lazy(() => import('./authenticated-app'))
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

const sessionTokenExists = () => !!AppStorage.getSession()
const isLocalStorageModified = e =>
  e.key === StorageSettings.sessionKey ||
  e.key === null ||
  !sessionTokenExists()

function App() {
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    const handleLocalStorageEvent = e => {
      if (!sessionTokenExists()) {
        AppStorage.clear()
      }

      if (isLocalStorageModified(e)) {
        window.location.reload()
      }
    }
    window.addEventListener('storage', handleLocalStorageEvent)

    return () => {
      window.removeEventListener('storage', handleLocalStorageEvent)
    }
  }, [])

  return (
    <AppContainer>
      <React.Suspense fallback={<LoadingPage />}>
        {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </AppContainer>
  )
}

export default App
