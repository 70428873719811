import React from 'react'
import { makeStyles } from '@material-ui/core'
import styles from './style'

const useStyles = makeStyles(styles)

export const FormalooLoader = ({ style, size }) => {
  const classes = useStyles()

  return (
    <div
      className={classes.loaderWrapper}
      style={{
        minHeight: size || '90vh',
        ...style,
      }}
    >
      <div className={classes.root} dir="ltr">
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export function FullPageLoader() {
  const classes = useStyles()

  return (
    <div className={classes.fullPage}>
      <FormalooLoader />
    </div>
  )
}

export default FormalooLoader
