import React from 'react'
import { useAuth } from '../auth.context'

const UserContext = React.createContext()

const UserProvider = props => {
  const { user } = useAuth()

  return <UserContext.Provider value={user} {...props} />
}

// and the useUser hook is basically this:
const useUser = () => {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export { UserContext, UserProvider, useUser }
