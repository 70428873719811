import { isRTL } from 'settings'
import { logger } from '.'

export const flattenArray = list =>
  list.reduce((a, b) => a.concat(Array.isArray(b) ? flattenArray(b) : b), [])

export const notification = (messages, type = 'info') => {
  if (!Array.isArray(messages)) {
    logger[type](messages, type)
    return
  }
  messages.forEach(message => {
    logger[type](message, type)
  })
}

export const showErrors = (errors = {}, notify, setError = null) => {
  // server errors = 500
  if (errors.response && errors.response.status >= 500) {
    notification(
      isRTL()
        ? 'خطای سرور! دوباره تلاش نمایید'
        : 'Server error! Please try again',
      'error',
    )
    return
  }

  // general errors < 500
  if (errors.general_errors && errors.general_errors.length > 0) {
    notify.error(flattenArray(errors.general_errors))
  }

  // form errors < 500
  if (errors.form_errors && setError) {
    const fieldsHasError = Object.keys(errors.form_errors)
    // eslint-disable-next-line array-callback-return
    fieldsHasError.map(field => {
      let fieldErrors = ''
      // eslint-disable-next-line array-callback-return
      errors.form_errors[field].map(err => {
        fieldErrors = `${fieldErrors} - ${err} `
      })
      setError(field, { message: fieldErrors })
    })
  } else {
    const fieldsHasError = Object.keys(errors.form_errors)
    // eslint-disable-next-line array-callback-return
    fieldsHasError.map(field => {
      // eslint-disable-next-line array-callback-return
      errors.form_errors[field].map(err => {
        notify.error(err)
      })
    })
  }
}
