import { config, getCurrentBusiness } from './utils'
import { httpClient } from 'utils/http'
import { logger } from 'utils/logger'

import OAuthServer from 'settings/oauth.settings'
import API from 'settings/api.settings'
import { AppStorage } from 'utils/persistence'
import { createFormData } from 'utils/form'

const activeBusiness = getCurrentBusiness()

export const auth = () => ({
  getAccessToken: () =>
    httpClient.post(
      OAuthServer.authorizationUrl,
      null,
      config({
        accessToken: AppStorage.getSession(),
        apiKey: OAuthServer.apiKey,
      }),
    ),

  getOAuthRedirectUrl: () =>
    httpClient.post(`${API.baseUrl}/v1/icas/request-redirect/`, null, config()),

  getOAuthAuthorizationCode: formData =>
    httpClient.post(`${API.baseUrl}/v1/icas/confirm-code/`, formData, config()),

  getLogoutUrl: () => `${OAuthServer.baseUrl}/profiles/logout/`,

  getProfileData: () =>
    httpClient.get(
      `${OAuthServer.baseUrl}/v1/profiles/profile/me/`,
      config({ apiKey: OAuthServer.apiKey }),
    ),

  updateProfileData: formData =>
    httpClient.patch(
      `${OAuthServer.baseUrl}/v1/profiles/profile/me/`,
      formData,
      config({ apiKey: OAuthServer.apiKey }),
    ),

  changePassword: formData =>
    httpClient.post(
      `${OAuthServer.baseUrl}/v1/profiles/change-password/`,
      formData,
      config({ apiKey: OAuthServer.apiKey }),
    ),

  getBusinessData: () =>
    httpClient.get(
      `${OAuthServer.baseUrl}/v2/businesses/${activeBusiness}/`,
      config({ apiKey: OAuthServer.apiKey }),
    ),

  updateBusinessData: formData =>
    httpClient.patch(
      `${OAuthServer.baseUrl}/v2/businesses/${activeBusiness}/`,
      createFormData(formData),
      config({ apiKey: OAuthServer.apiKey }),
    ),
})

export async function getOrRefreshToken() {
  const {
    // data: {
    errors,
    token,
    // },
  } = await auth().getAccessToken()

  if (errors) {
    logger.error(errors.general_errors)
    // logout user here
    return Promise.reject(errors.general_errors)
  }

  if (token) {
    // set user token here to the store? or persist somewhere
    AppStorage.setToken(token)
    return Promise.resolve(token)
  }
}
