import API from 'settings/api.settings'
import { AppStorage } from 'utils/persistence'

const headers = (apiKey = API.apiKey) => {
  return {
    'Content-Type': 'application/json',
    'x-api-key': apiKey,
    accept: 'application/json',
  }
}

const baseHttpConfig = {
  validateStatus: status => status < 400,
  headers: headers(),
}

const generateAuthHeaders = (accessToken, settings) => ({
  ...headers(settings && settings.apiKey),
  Authorization: `JWT ${accessToken}`,
})

export const generateConfig = (accessToken, additionalSettings) => {
  const token = additionalSettings.accessToken || accessToken

  if (!token) {
    const config = { ...baseHttpConfig, ...additionalSettings }
    return config
  }

  const headerSettings = {
    headers: generateAuthHeaders(token, additionalSettings),
  }
  const config = {
    ...baseHttpConfig,
    ...headerSettings,
    ...additionalSettings,
  }
  return config
}

// export const getAccessToken = () => sessionStorage.getItem('token')
export const getAccessToken = () => AppStorage.getToken()

// ______ this is business's slug on actions server ________________________________________
export const getCurrentOrganization = () =>
  AppStorage.getOrganization('actionBusiness')

export const setCurrentOrganization = value =>
  AppStorage.setOrganization('actionBusiness', value)

export const removeCurrentOrganization = () =>
  AppStorage.removeOrganization('actionBusiness')

// ______ this is business_identifier ______________________________________________________
export const getCurrentBusiness = () => AppStorage.get('activeBusiness')
export const setCurrentBusiness = value =>
  AppStorage.set('activeBusiness', value)
export const removeCurrentBusiness = () => AppStorage.remove('activeBusiness')

// ______________________________________________________
export const config = (additionalSettings = {}) =>
  generateConfig(getAccessToken(), additionalSettings)
