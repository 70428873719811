import StorageSettings from 'settings/storage.settings'

class AppStorage {
  token = null

  get = key => window.localStorage.getItem(key)
  remove = key => window.localStorage.removeItem(key)
  set = (key, value) => window.localStorage.setItem(key, value)
  clear = () => {
    this.setToken(null)
    window.localStorage.clear()
  }

  getSession = () => this.get(StorageSettings.sessionKey)
  setSession = value => this.set(StorageSettings.sessionKey, value)
  removeSession = () => this.remove(StorageSettings.sessionKey)

  getOrganization = key => this.get(key)
  setOrganization = (key, value) => this.set(key, value)
  removeOrganization = key => this.remove(key)

  setToken = token => (this.token = token)
  getToken = () => this.token
}

const AppStorageInstance = new AppStorage()
export default AppStorageInstance
