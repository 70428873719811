import { config } from './utils'
import { httpClient } from 'utils/http'

import API from 'settings/actions.settings'

export const location = () => ({
  getAllCities: (params = {}) =>
    httpClient.get(
      `${API.baseUrl}/v2/cities/`,
      config({
        apiKey: API.apiKey,
        params,
      }),
    ),
})
