import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { useOrganization } from 'context/private/organization.context'
import Logo from 'components/common/logo'
import ProfileMenu from './wideScreen-profile'
import ProfileMobileScreenMenu from './mobileScreen-menu'

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
    '&>a': {
      margin: theme.spacing(0, 2),
      display: 'block',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 2%',
    minHeight: '60px',
    [theme.breakpoints.up('xl')]: {
      padding: '8px 10%',
    },
  },
  createInvoice: {
    [theme.breakpoints.up('md')]: {
      width: 150,
      minHeight: 40,
    },
    width: 50,
    minHeight: 35,
    margin: theme.spacing(0, 1.5),
  },
  links: {
    textDecoration: 'none',
    margin: theme.spacing(0, 2),
    color: 'black',
    fontSize: 15,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  activeLink: {
    // borderBottom: `2px solid`,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  linkRoot: { color: 'black' },
}))

const Links = ({ linksArray }) => {
  const classes = useStyles()
  const { organization } = useParams()
  const { pathname } = useLocation()
  const isActive = id =>
    Boolean(
      [`/${organization}/${id}`, `/${organization}/${id}/`].includes(pathname),
    )
  return linksArray.map((link, index) => (
    <Link
      key={index}
      to={link.url}
      as={link.url}
      className={clsx(classes.links, isActive(link.id) && classes.activeLink)}
      classes={{ root: classes.linkRoot }}
    >
      {link.title}
    </Link>
  ))
}

const AppMenu = ({ width }) => {
  const classes = useStyles()
  const { organization } = useOrganization()
  const { formatMessage } = useIntl()

  const linksArray = [
    {
      title: formatMessage({ id: 'header.links.dashboard' }),
      id: 'home',
      url: `/${organization && organization.slug}/home`,
    },
    {
      title: formatMessage({ id: 'header.links.customers' }),
      id: 'customers',
      url: `/${organization && organization.slug}/customers`,
    },
    {
      title: formatMessage({ id: 'header.links.campaigns' }),
      id: 'campaigns',
      url: `/${organization && organization.slug}/campaigns`,
    },
    {
      title: formatMessage({ id: 'tags' }),
      id: 'tags',
      url: `/${organization && organization.slug}/tags`,
    },
    {
      title: formatMessage({ id: 'integrations' }),
      id: 'integrations',
      url: `/${organization && organization.slug}/integrations`,
    },
    {
      title: formatMessage({ id: 'tags.mapView' }),
      id: 'tags/map-view',
      url: `/${organization && organization.slug}/tags/map-view`,
    },
  ]

  const wideScreen = isWidthUp('md', width)

  return (
    <div className={[classes.grow, classes.root].join(' ')}>
      <AppBar position="sticky">
        <Toolbar>
          <Logo />
          {wideScreen && organization && <Links linksArray={linksArray} />}
          {wideScreen ? (
            <ProfileMenu />
          ) : (
            <ProfileMobileScreenMenu linksArray={linksArray} />
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}
export default withWidth()(AppMenu)
