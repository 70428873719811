import { config } from './utils'
import { httpClient } from 'utils/http'

import API from 'settings/actions.settings'
import FORMALOO_API from 'settings/formaloo.settings'

import { createFormData } from 'utils/form'

export const customers = () => ({
  getAll: params =>
    httpClient.get(`${API.baseUrl}/v2/customers/`, config({ params })),

  create: formValues =>
    httpClient.post(
      `${FORMALOO_API.baseUrl}/v1.0/customers/`,
      createFormData(formValues),
      config(),
    ),

  updateV2: (code, formValues, settings = {}) =>
    httpClient.patch(
      `${FORMALOO_API.baseUrl}/v1.0/customers/${code}/`,
      createFormData(formValues, settings),
      config(),
    ),

  update: (code, formValues, settings) =>
    httpClient.patch(
      `${API.baseUrl}/v2/customers/${code}/`,
      createFormData(formValues, settings),
      config({
        apiKey: API.apiKey,
      }),
    ),

  getOne: code =>
    httpClient.get(
      `${API.baseUrl}/v2/customers/${code}/`,
      config({
        apiKey: API.apiKey,
      }),
    ),

  deleteOne: code =>
    httpClient.delete(
      `${API.baseUrl}/v2/customers/${code}/`,
      config({
        apiKey: API.apiKey,
      }),
    ),

  addNote: (formValues, customerCode) =>
    httpClient.post(
      `${API.baseUrl}/v2/customers/${customerCode}/notes/`,
      createFormData(formValues),
      config({
        apiKey: API.apiKey,
      }),
    ),

  addField: formValues =>
    httpClient.post(
      `${API.baseUrl}/v2/fields/`,
      createFormData(formValues),
      config({
        apiKey: API.apiKey,
      }),
    ),

  updateField: (slug, formValues) =>
    httpClient.patch(
      `${API.baseUrl}/v2/fields/${slug}/`,
      createFormData(formValues),
      config({
        apiKey: API.apiKey,
      }),
    ),

  getLanguages: (params = '') =>
    httpClient.get(
      `${API.baseUrl}/v2/languages/${params}`,
      config({
        apiKey: API.apiKey,
      }),
    ),

  importExcel: (formValues, params = {}) =>
    httpClient.post(
      `${FORMALOO_API.baseUrl}/v1.0/customers/batch/`,
      createFormData(formValues),
      config({ params }),
    ),

  getImportStatus: slug =>
    httpClient.get(
      `${FORMALOO_API.baseUrl}/v1.0/customers/batch/${slug}/`,
      config(),
    ),
})
