import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  jssPreset,
} from '@material-ui/core/styles'
import { create } from 'jss'
import rtl from 'jss-rtl'

import { theme } from 'theme'
import { isRTL } from 'settings'
import { IntlContext } from './intl.context'

// TODO: read from intl context
const defaultThemeDirection = isRTL() ? 'rtl' : 'ltr'

const ThemeContext = React.createContext()

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function RTL(props) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>
}

class ThemeProviderClass extends React.Component {
  static contextType = IntlContext

  constructor(...args) {
    super(...args)

    this.switchThemeDirection = themeDirection => {
      this.setState({ themeDirection }, () =>
        document.body.setAttribute('dir', themeDirection),
      )
    }

    document.body.setAttribute('dir', defaultThemeDirection)
    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      themeDirection: defaultThemeDirection,
      switchThemeDirection: this.switchThemeDirection,
    }
  }

  render() {
    const { children } = this.props
    const { themeDirection } = this.state

    return (
      <ThemeContext.Provider value={this.state}>
        <MuiThemeProvider theme={theme(themeDirection, this.context.locale)}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {themeDirection === 'rtl' ? <RTL>{children}</RTL> : children}
        </MuiThemeProvider>
      </ThemeContext.Provider>
    )
  }
}

export { ThemeProviderClass as ThemeProvider, ThemeContext }
