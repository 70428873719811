import { config } from './utils'
import { httpClient } from 'utils/http'

import API from 'settings/actions.settings'
import { createFormData } from 'utils/form'

export const tags = () => ({
  get: slug => httpClient.get(`${API.baseUrl}/v2/tags/${slug}/`, config()),

  // TODO: fix/refactor: pass params to config like "getAllCorrected" but watchout for the all usage
  getAll: (params = '') =>
    httpClient.get(`${API.baseUrl}/v2/tags/${params}`, config()),

  getAllCorrected: params =>
    httpClient.get(`${API.baseUrl}/v2/tags/`, config({ params })),

  create: formValues =>
    httpClient.post(
      `${API.baseUrl}/v2/tags/`,
      createFormData(formValues),
      config(),
    ),

  update: (slug, formValues) =>
    httpClient.patch(
      `${API.baseUrl}/v2/tags/${slug}/`,
      createFormData(formValues),
      config(),
    ),
})
