import { config, getCurrentBusiness } from './utils'
import { httpClient } from 'utils/http'

import API from 'settings/api.settings'
import { createFormData } from 'utils/form'

const active_business = getCurrentBusiness()

export const campaign = () => ({
  getAll: params =>
    httpClient.get(
      `${API.baseUrl}/v1/campaigns/list/`,
      config({ params: { ...params, active_business } }),
    ),

  getOne: slug =>
    httpClient.get(
      `${API.baseUrl}/v1/campaigns/campaign/${slug}/`,
      config({ params: { active_business } }),
    ),

  create: formValues =>
    httpClient.post(
      `${API.baseUrl}/v1/campaigns/campaign/`,
      createFormData(formValues),
      config({ params: { active_business } }),
    ),

  update: (slug, formValues) =>
    httpClient.patch(
      `${API.baseUrl}/v1/campaigns/campaign/${slug}/update/`,
      createFormData(formValues),
      config({ params: { active_business } }),
    ),

  updateStatus: (slug, formValues) =>
    httpClient.patch(
      `${API.baseUrl}/v1/campaigns/campaign/${slug}/status/`,
      createFormData(formValues),
      config({ params: { active_business } }),
    ),

  delete: slug =>
    httpClient.delete(
      `${API.baseUrl}/v1/campaigns/campaign/${slug}/update/`,
      config({ params: { active_business } }),
    ),
})
