export default theme => ({
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    '& > *': {
      display: 'flex',
      direction: 'ltr',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '16.5%',
      transition: '0.5s',
      borderRadius: '20px',
      backgroundSize: '600% 100%',
      animationDirection: 'alternate',
      animation: `$logoBlink 1s linear infinite`,
      background:
        'linear-gradient(45deg, #FF3706, #f46444 , #F17C58, #565988,  #0957df, #0050db)',
      '&:first-child': {
        width: '75%',
      },
      '&:nth-child(2)': {
        width: '46%',
        margin: '11% 0',
      },
      '&:nth-child(3)': {
        width: '21.5%',
      },
    },
  },
  fullPage: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(179, 178, 178, 0.5)',
    '& $root': {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  },
  '@keyframes logoBlink': {
    '0%': { backgroundPosition: '0%' },
    '100%': { backgroundPosition: '100%' },
  },
})
