import React from 'react'
import {
  makeStyles,
  IconButton,
  Drawer,
  List,
  ListItem,
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined'
import BusinessIcon from '@material-ui/icons/Business'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import placeholder from 'assets/images/avatarPlaceholder.png'
import { useOrganization } from 'context/private/organization.context'
import { useAuth } from 'context/auth.context'
import { shouldFlip } from 'theme'

const useStyles = makeStyles(theme => ({
  profileWrapper: {
    flip: shouldFlip(theme),
    marginLeft: 'auto',
    '& a, a:hover': {
      textDecoration: 'none',
    },
  },
  button: {
    padding: 0,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    overflow: 'hidden',
    transition: '0.4s',
    '& >img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '&:hover': {
      boxShadow: '0px 0px 6px rgba(0,0,0,0.4)',
    },
  },
  menuIcon: {
    fill: theme.palette.primary.main,
    width: 30,
    height: 30,
  },
  listWrapper: {
    width: 270,
    height: '100vh',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  userIfo: {
    width: '100%',
    minHeight: '75px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.common.gray}`,
    paddingBottom: theme.spacing(1),
    '& > img': {
      flip: shouldFlip(theme),
      width: 70,
      height: 70,
      borderRadius: '50%',
      objectFit: 'cover',
      marginRight: theme.spacing(1),
    },
  },
  dashboardLinks: {
    '& > li': {
      width: '100%',
      minHeight: 43,
      padding: 0,
      '&>*': {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        color: 'black',
        fontSize: 14,
      },
    },
  },
  actionList: {
    marginTop: 'auto',
    borderTop: `1px solid ${theme.palette.common.gray}`,
    '& > li': {
      width: '100%',
      minHeight: 40,
      padding: 0,
      '&>*': {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        color: 'black',
        fontSize: 14,
        '& svg': {
          flip: shouldFlip(theme),
          fill: theme.palette.primary.main,
          width: 20,
          height: 20,
          marginRight: 5,
        },
      },
    },
  },
}))

const ProfileMobileScreenMenu = ({ linksArray }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const { organization, logoutOrganization } = useOrganization()
  const { user, logout } = useAuth()
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.profileWrapper}>
      <IconButton
        aria-label="profile"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
        classes={{
          root: classes.button,
        }}
      >
        {/* <ProfileAvatar avatar={avatar} /> */}
        <MenuOutlinedIcon
          classes={{
            root: classes.menuIcon,
          }}
        />
      </IconButton>
      {user && (
        <Drawer open={open} onClose={handleClose} anchor="right">
          <div className={classes.listWrapper}>
            <div className={classes.userIfo}>
              <img src={user.avatar || placeholder} alt="" />
              <p>{user.first_name}</p>
            </div>

            {/* dashboard links */}
            {organization && (
              <List className={classes.dashboardLinks}>
                {linksArray.map(link => (
                  <ListItem key={link.id} onClick={handleClose}>
                    <Link
                      to={link.url}
                      as={link.url}
                      className={classes.links}
                      classes={{ root: classes.linkRoot }}
                      // style={param === link.id ? { color: '#0065DC', fontWeight: 'bolder' } : null}
                    >
                      {link.title}
                    </Link>
                  </ListItem>
                ))}
              </List>
            )}

            {/* profile links */}
            <List className={classes.actionList}>
              <ListItem onClick={handleClose}>
                <Link to={`/user-profile`} as={`/user-profile`}>
                  <EditOutlinedIcon />
                  <p>{user.first_name}</p>
                </Link>
              </ListItem>
              {organization && (
                <ListItem onClick={handleClose} className={classes.menuItem}>
                  <Link
                    to={`/${organization.slug}/company-profile`}
                    as={`/${organization.slug}/company-profile`}
                  >
                    <BusinessIcon color="primary" />
                    <p>{organization.title}</p>
                  </Link>
                </ListItem>
              )}
              {organization && (
                <ListItem onClick={handleClose} className={classes.menuItem}>
                  <div onClick={logoutOrganization}>
                    <SwapHorizIcon color="primary" />
                    <p>
                      <FormattedMessage id="header.links.changeOrganization" />
                    </p>
                  </div>
                </ListItem>
              )}
              <ListItem onClick={handleClose}>
                <div onClick={logout}>
                  <ExitToAppOutlinedIcon />
                  <p>
                    <FormattedMessage id="general.logout" />
                  </p>
                </div>
              </ListItem>
            </List>
          </div>
        </Drawer>
      )}
    </div>
  )
}

export default ProfileMobileScreenMenu
