import React from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import AppMenu from './menu'

function ElevationScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

export default function AppHeader(props) {
  return (
    <ElevationScroll {...props}>
      <AppMenu />
    </ElevationScroll>
  )
}
